.search {
  display: flex;
  input {
    flex: 1;
    background-color: #3b3b3b;
    color: white;
    padding: 12px;
    border: 0;
    border-radius: 2px 0 0 2px; }
  button {
    padding: 12px;
    border-radius: 0 2px 2px 0;
    border: 0;
    background-color: #3b3b3b;
    color: white;
    &:hover {
      cursor: pointer;
      background-color: #343333; } } }
