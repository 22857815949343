ul.events {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  list-style-type: none;
  & > li {
    padding: 0;
    height: auto;
    margin: 10px 0px;
    width: 32%;
    border-radius: 2px;
    cursor: pointer;
    background-color: #3b3b3b;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    &:last-child {
      margin-bottom: 0; }
    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      img {
        width: 100%; } }
    .event-title {
      color: white;
      padding: 5px 10px;
      font-weight: 600; }
    .event-description {
      font-size: 13px;
      padding: 5px 10px;
      overflow: auto;
      color: rgba(255, 255, 255, 0.8); }
    &:hover {
      background-color: #343333; } } }


